import React from "react";

export default function MainContent() {
  return (
    <>
      <div
        data-parallax-bg-image="/assets/images/team/abstract-bg.png"
        data-parallax-bg-speed="1"
        className="vc_row wpb_row vc_row-fluid vc_row-o-content-top vc_row-flex mkdf-parallax-row-holder mkdf-content-aligment-right"
        style={{
          backgroundColor: "#ebf0ff",
          backgroundImage: "url(/assets/images/team/abstract-bg.png)",
          backgroundPosition: "50% 0px",
        }}
      >
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-right">
                <div
                  className="mkdf-eh-item mkdf-horizontal-alignment-right"
                  data-item-class="mkdf-eh-custom-5733"
                  data-1367-1600="125px 80px 47px 687px"
                  data-1025-1366="85px 134px 17px 709px"
                  data-769-1024="80px 15px 30px 524px"
                  data-681-768="60px 114px 278px"
                  data-680="30px 40px 260px"
                >
                  <div className="mkdf-eh-item-inner">
                    <div
                      className="mkdf-eh-item-content mkdf-eh-custom-5733"
                      style={{ padding: "101px 50px 0px 833px" }}
                    >
                      <div
                        className="mkdf-animation-holder mkdf-element-from-right"
                        data-animation="mkdf-element-from-right"
                        data-animation-delay
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="mkdf-animation-inner">
                          <div className="mkdf-single-image-holder">
                            <div className="mkdf-si-inner">
                              <img
                                width="769"
                                height="666"
                                src="/assets/images/contact-us/contact-bg.svg"
                                className="attachment-full size-full"
                                alt="contact-us-img"
                                decoding="async"
                                loading="lazy"
                                style={{
                                  width: "506px",
                                  height: "474px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-top vc_row-flex">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1528987920710">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-center">
                    <div
                      className="mkdf-eh-item"
                      data-item-class="mkdf-eh-custom-9172"
                      data-1367-1600="80px 56% 106px 80px"
                      data-1025-1366="162px 56% 130px 0px"
                      data-769-1024="134px 42% 109px 0"
                      data-681-768="270px 8% 0px"
                      data-680="270px 0px 0px 22px"
                    >
                      <div className="mkdf-eh-item-inner">
                        <div
                          className="mkdf-eh-item-content mkdf-eh-custom-9172"
                          style={{ padding: "0 63% 0 0" }}
                        >
                          <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space">
                            <div className="mkdf-st-inner">
                              <h2
                                className="mkdf-st-title"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                We’ll Be Glad
                                <br />
                                To Assist You!
                              </h2>
                              <p
                                className="mkdf-st-text font-size-line-height"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                If you want to enquire about our project or have
                                any other queries, Kindly reach us by fill-up
                                the below form.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
