import React from "react";

export default function WhoWeAre() {
  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="mkdf-eh-item-inner">
            <div className="mkdf-eh-item-content mkdf-eh-custom-2383 p-lg-5 p-md-5">
              <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space text-center">
                <div className="mkdf-st-inner">
                  <h3
                    className="mkdf-st-title"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Who We Are?
                  </h3>
                  <p
                    className="mkdf-st-text font-size-line-height"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    We offer custom development, backend development, enterprise
                    services, and CMS website development. We have also
                    experienced professionals for native iOS, and Android mobile
                    applications & cross-platform technology such as flutter and
                    react native. We provide end-to-end support for all our
                    projects. Satisfied clients around the globe bear testimony
                    to the quality of our work.
                  </p>

                  <p
                    className="mkdf-st-text font-size-line-height"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    Our 6+ years of experience in the industry and our
                    results-oriented approach has never failed us in delivering
                    pure satisfaction to our clients. Associate with us and get
                    the advantage of our technology expertise to gain simplified
                    business solutions and fulfill all your business needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
