import React from "react";

export default function BenifitPriceModel() {
  const benefitsModel = [
    {
      title:
        "Before beginning a project, its resources, duration, and budget are laid out.",
    },
    { title: "Less uncertainty due to fixed costs and schedules." },
    {
      title:
        "Consent is required for any scope changes; we will never go above.",
    },
    { title: "Payment is due at the end of each milestone." },
    {
      title:
        "We'll appoint a technical lead to assist you in overseeing your project with no additional cost.",
    },
  ];

  return (
    <div className="mkdf-row-grid-section-wrapper pt-3">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="mkdf-eh-item-inner">
            <div className="mkdf-eh-item-content mkdf-eh-custom-2383 pb-5">
              <div className="d-flex">
                <div className="col-md-12">
                  <div className="d-lg-flex justify-content-between p-3">
                    <div className="col-lg-6 col-md-12">
                      <h3
                        className="fs-3 fw-bold"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        Benefits of our Fixed Price Model
                      </h3>
                      <ul className="ul-list-style">
                        {benefitsModel?.map((item, key) => {
                          return (
                            <li
                              className="mkdf-st-text font-size-line-height"
                              key={key}
                              data-aos="fade-right"
                              data-aos-duration="1000"
                            >
                              {item?.title}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-md-5" data-aos="fade-left"
                        data-aos-duration="1000">
                      <img
                        width="100%"
                        height="auto"
                        src="/assets/images/services/method-amico.svg"
                        className="attachment-full size-full"
                        alt="service-diagram-img"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
