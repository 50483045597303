import React from "react";
import moment from "moment";
import ReactWhatsapp from "react-whatsapp";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Details } from "../../../../constant/Details";

export default function JobContent({ listdata, joblink }) {
  return (
    <div
      className="col-lg-8 col-md-12"
      style={{
        borderRadius: "4px",
        padding: "4px",
      }}
    >
      <div className="mkdf-iwt-content w-100">
        <div className="d-lg-flex">
          <div className="col-lg-1">
            <img
              width="100"
              height="auto"
              src={listdata?.image}
              className="attachment-full size-full border-radius-100 mark"
              alt="jobtitle-img"
              decoding="async"
              loading="lazy"
            />
          </div>
          <div className="col-lg-11 mkdf-iwt-title ms-lg-3">
            <div className="mkdf-iwt-title-text d-lg-flex justify-content-between">
              <div className="d-flex m-0">
                <h4 className="m-0 text-nowrap jobtitle-small">
                  {listdata?.job_title}
                </h4>
                <span className="ms-2 verified-img">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-patch-check-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                  </svg>
                </span>
              </div>
              <p
                className="me-lg-4"
                style={{
                  textTransform: "none",
                  borderRadius: "50px",
                  background: "#28a745",
                  color: "#fff",
                  padding: "0px 10px",
                  width: "max-content",
                  fontSize: "14px",
                }}
              >
                {listdata?.job_type}
              </p>
            </div>
            <div className="row gy-2 text-secondary pb-3">
              <div className="col-auto">
                <span className="font-size-16 fw-normal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="currentColor"
                    className="bi bi-briefcase"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"></path>
                  </svg>
                  <span className="ms-2">{listdata?.experience}</span>
                </span>
              </div>
              <div className="col-auto">
                <span className="font-size-16 fw-normal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="currentColor"
                    className="bi bi-geo-alt"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"></path>
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                  </svg>
                  <span className="ms-2">{listdata?.location}</span>
                </span>
              </div>
              <div className="col-auto">
                <span className="font-size-16 fw-normal">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="currentColor"
                    className="bi bi-clock"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path>
                  </svg>
                  <span className="ms-2">
                    {moment(listdata?.time).fromNow()}
                  </span>
                </span>
              </div>
            </div>
            <div className="row gy-2">
              {listdata?.language?.map((item, key) => {
                let colors = ["#28a745", "orange", "#0f92fb", "red", "#45457a"];
                const backgroundColor = colors[key % colors?.length];

                return (
                  <div className="col-auto" key={key}>
                    <div
                      className="text-white text-center"
                      style={{
                        borderRadius: "50px",
                        background: backgroundColor,
                        padding: "2px 10px",
                        fontSize: "14px",
                      }}
                    >
                      {item?.technologies}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mkdf-iwt-content w-100">
        <h4 className="mb-0">Company Overview</h4>
        <p className="mt-0">
          The {Details?.COMPANY_NAME} Team incorporated as an entity promoted by
          well-qualified engineers, computer professionals, and businessmen
          specializing in Data Conversion, IT Enabled Services, Hotel Management
          Software, Accounting Software, Payroll Management Software, CRM
          Software, and Enterprise Software, who bring with them several years
          of business and professional experience. This rich experience of
          promoters in various industries helps the organization assimilate
          customer requirements at a faster pace. In the global business arena,
          the world has been divided into small platforms in order to deliver
          the market requisites in no time. The rapid growth and mind-boggling
          pace of changes in technology have compelled the business world to
          integrate the latest technology amendments.
        </p>
      </div>
      <div className="mkdf-iwt-content w-100">
        <h4 className="mb-0">Job Responsibilities</h4>
        <p className="mt-0">{listdata?.Duties}</p>
        <ul className="ul-list-style m-3">
          {listdata?.roles?.map((data, key) => {
            return (
              <li className="mb-1" key={key}>
                {data?.description}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="mkdf-iwt-content w-100">
        <h4 className="mb-0">Candidate Requirement</h4>
        <ul className="ul-list-style m-3">
          {listdata?.requirements?.map((data, key) => {
            return (
              <li className="mb-1" key={key}>
                <span>{data?.description}</span>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="mkdf-iwt-content w-100">
        <ul className="d-lg-flex d-md-flex align-items-center list-unstyled">
          <h4 className="me-4">Share Job :</h4>
          <div className="d-flex">
            <li className="me-4">
              <ReactWhatsapp
                number=""
                message={`I just found new job opening at ${Details?.COMPANY_NAME} ! It’s a global software service provider of web applications and mobile app development  since 2016. having work with a fun environment that enhances our productivity.  You can easily apply via below link : \t ${joblink}`}
                className="border-0 p-0"
              >
                <WhatsappIcon size={30} />
              </ReactWhatsapp>
            </li>
            <li className="me-4">
              <a
                href={`mailto:${Details?.emailAddress}?subject=Interesting Job opening for ${listdata?.job_title}&body=Hi, \n \n Hope you all are doing great ! \n\n I just found new job opening at ${Details?.COMPANY_NAME} ! It’s a global software service provider of web applications and mobile app development  since 2016. having work with a fun environment that enhances our productivity. \n \n You can easily apply via below link : \t ${joblink} \n \n Looking forward to some positive response. \n \n You can directly reach us on ${Details?.emailAddress} \n \n Thank you ! \n \n Regards, \n ${Details?.COMPANY_NAME}`}
                data-rel="external"
              >
                <svg viewBox="0 0 64 64" width="30" height="30">
                  <rect
                    width="64"
                    height="64"
                    rx="0"
                    ry="0"
                    fill="#7f7f7f"
                  ></rect>
                  <path
                    d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z"
                    fill="white"
                  ></path>
                </svg>
              </a>
            </li>
            <li className="me-4">
              <FacebookShareButton
                quote={"Interesting Job opening for " + listdata?.job_title}
                hashtag="#3elixir #jobopening #surat "
                url={joblink}
                description={"aiueo"}
              >
                <FacebookIcon size={30} />
              </FacebookShareButton>
            </li>
            <li className="me-4">
              <LinkedinShareButton
                title={"Interesting Job opening for " + listdata?.job_title}
                summary={`I just found new job opening at ${Details?.COMPANY_NAME}  `}
                url={joblink}
                source={"https://3elixir.com"}
              >
                <LinkedinIcon size={30} />
              </LinkedinShareButton>
            </li>
            <li className="me-4">
              <TwitterShareButton
                title={"Interesting Job opening for " + listdata?.job_title}
                via={`3elixir \n You can easily apply via below link : \t ${joblink} \n \n Looking forward to some positive response. \n \n You can directly reach us at ${Details?.emailAddress}`}
                url={joblink}
                hashtag="#3elixir #jobopening #surat"
              >
                <TwitterIcon size={30} />
              </TwitterShareButton>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}
