import React from "react";
import MainContent from "./MainContent";
import Teams from "./Teams";

export default function OurTeam() {
  return (
    <>
      <div className="mkdf-full-width">
        <div className="mkdf-full-width-inner">
          <div className="mkdf-grid-row">
            <div className="mkdf-page-content-holder mkdf-grid-col-12">
              <section className="wpb-content-wrapper">
                <MainContent />

                <Teams />
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
