import React from "react";
import { Link } from "react-router-dom";
import { Details } from "../../../constant/Details";
import { Style } from "../../../common/Style";

export default function OurServices() {
  const ourServices = [
    {
      image: "/assets/images/services/application-development.svg",
      name: "Application Development",
      alt: "apk-development-img",
      description: `Unleash Success with The ${Details?.COMPANY_NAME}: Your Trusted Partner for Application Development Choose The ${Details?.COMPANY_NAME} as your trusted partner for application development, and experience the assurance of punctuality and unparalleled quality. We don't just meet industry standards; we set them. Your success is our mission, and our track record reflects that commitment.`,
    },
    {
      image: "/assets/images/services/web-design.svg",
      name: "Custom Web Design",
      alt: "web-design-img",
      description:
        "Elevate Your Digital Presence with Expert Web Design your website is often the first impression potential customers have of your business. Make it a lasting one with our top-notch web design services. Our team of experienced designers specializes in creating visually stunning, user-friendly websites tailored to your brand and business goals.",
    },
    {
      image: "/assets/images/services/software-development.svg",
      name: "Software Development",
      alt: "software-development-img",
      description: `At ${Details?.COMPANY_NAME}, we are proud to be an associated enterprise with a software firm that doesn't just deliver projects; we deliver success. Our approach is defined by three core principles: dedication, commitment, and a delegated focus.`,
    },
  ];

  return (
    <>
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1528805580898">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  <h3
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Our Services
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_1529575862598">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-three-columns mkdf-responsive-mode-1024">
                    <div className="row">
                      {ourServices?.map((item, key) => {
                        return (
                          <div
                            className="col-xl-4 col-md-6 mb-3 d-flex align-items-stretch"
                            key={key}
                          >
                            <div className="card shadow border-0">
                              <div className="card-body d-flex flex-column align-items-center text-align-center mkdf-iwt-inner">
                                <img
                                  width="64"
                                  height="64"
                                  src={item?.image}
                                  className="attachment-full size-full"
                                  alt={item?.alt}
                                  decoding="async"
                                  loading="lazy"
                                />
                                <h4 className="mb-0">{item?.name}</h4>
                                <p className="mkdf-st-text font-size-line-height mb-4">
                                  {item?.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center pt-3 pb-4">
                    <Link
                      to="/our-services"
                      className="text-white p-2"
                      style={Style.portfolioButton}
                    >
                      View All Services
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
