import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const { pathname } = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const headerMenuData = [
    { name: "Home", linkTo: "/" },
    { name: "About Us", linkTo: "/about-us" },
    { name: "Our Team", linkTo: "/our-team" },
    { name: "Our Clients", linkTo: "/our-clients" },
    { name: "Portfolio", linkTo: "/portfolio" },
    { name: "Services", linkTo: "/our-services" },
    { name: "Career", linkTo: "/career" },
    { name: "Contact Us", linkTo: "/contact-us" },
  ];

  return (
    <>
      <style jsx="true">{`
        .open-box {
          fill: #58468c;
        }
        .mkdf-light-header:not(.mkdf-sticky-header-appear) .open-box {
          fill: #5580ff !important;
        }

        .open-lines {
          fill: #ffffff !important;
        }
        .mkdf-light-header .open-lines {
          fill: #fff !important;
        }

        .mkdf-light-header
          .mkdf-page-header
          > div:not(.mkdf-sticky-header):not(.fixed)
          .mkdf-side-menu-button-opener:hover
          .open-box {
          fill: #ff8a73 !important;
        }

        .mkdf-main-menu ul li .item_outer:hover {
          background: #ee8b17;
          color: #fff;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
        }
        .activeClassBg {
          background: #ee8b17;
          color: #fff;
          border-radius: 5px;
        }
      `}</style>
      <header className="mkdf-page-header">
        <div className="mkdf-menu-area mkdf-menu-center">
          <div className="mkdf-vertical-align-containers">
            <div className="mkdf-position-left">
              <div className="mkdf-position-left-inner">
                <div className="mkdf-logo-wrapper">
                  <Link itemProp="url" to="/" style={{ height: "70px" }}>
                    <img
                      itemProp="image"
                      className="mkdf-normal-logo"
                      src="/assets/images/logo.png"
                      width="auto"
                      height="auto"
                      alt="logo"
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="mkdf-position-center d-flex justify-content-between">
              <div className="mkdf-position-center-inner">
                <nav className="mkdf-main-menu mkdf-drop-down mkdf-default-nav">
                  <ul id="menu-standard" className="clearfix">
                    {headerMenuData?.map((item, index) => {
                      return (
                        <li
                          className={`${
                            pathname === item?.linkTo ? "mkdf-active-item" : ""
                          } menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has_sub narrow`}
                          key={index}
                        >
                          {item?.name === "Portfolio" &&
                          item?.linkTo === "/portfolio" ? (
                            <a
                              href={item?.linkTo}
                              className={`${
                                pathname === item?.linkTo ? "current" : ""
                              }`}
                              onClick={() => {
                                window.location.reload();
                              }}
                            >
                              <span
                                className={`${
                                  pathname === item?.linkTo
                                    ? "activeClassBg"
                                    : ""
                                } item_outer p-2`}
                              >
                                <span className="item_text">Portfolio</span>
                                <i className="mkdf-menu-arrow fa fa-angle-down"></i>
                              </span>
                            </a>
                          ) : (
                            <Link
                              to={item?.linkTo}
                              className={`${
                                pathname === item?.linkTo ? "current" : ""
                              }`}
                            >
                              <span
                                className={`${
                                  pathname === item?.linkTo
                                    ? "activeClassBg"
                                    : ""
                                } item_outer p-2`}
                              >
                                <span className="item_text">{item?.name}</span>
                                <i className="mkdf-menu-arrow fa fa-angle-down"></i>
                              </span>
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="mkdf-sticky-header">
          <div className="mkdf-sticky-holder mkdf-menu-center">
            <div className="mkdf-vertical-align-containers">
              <div className="mkdf-position-left">
                <div className="mkdf-position-left-inner">
                  <div className="mkdf-logo-wrapper">
                    <Link itemProp="url" to="/" style={{ height: "70px" }}>
                      <img
                        itemProp="image"
                        className="mkdf-normal-logo"
                        src="/assets/images/logo.png"
                        width="auto"
                        height="auto"
                        alt="logo"
                        loading="lazy"
                      />
                      <img
                        itemProp="image"
                        className="mkdf-dark-logo"
                        src="/assets/images/logo.png"
                        width="auto"
                        height="auto"
                        alt="dark logo"
                        loading="lazy"
                      />
                      <img
                        itemProp="image"
                        className="mkdf-light-logo"
                        src="/assets/images/logo.png"
                        width="auto"
                        height="auto"
                        alt="light logo"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mkdf-position-center d-flex justify-content-between">
                <div className="mkdf-position-center-inner">
                  <nav className="mkdf-main-menu mkdf-drop-down mkdf-sticky-nav">
                    <ul id="menu-standard-1" className="clearfix">
                      {headerMenuData?.map((item, index) => {
                        return (
                          <li
                            className={`${
                              pathname === item?.linkTo
                                ? "mkdf-active-item"
                                : ""
                            } menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has_sub narrow`}
                            key={index}
                          >
                            {item?.name === "Portfolio" &&
                            item?.linkTo === "/portfolio" ? (
                              <a
                                href={item?.linkTo}
                                className={`${
                                  pathname === item?.linkTo ? "current" : ""
                                }`}
                                onClick={() => {
                                  window.location.reload();
                                }}
                              >
                                <span
                                  className={`${
                                    pathname === item?.linkTo
                                      ? "activeClassBg"
                                      : ""
                                  } item_outer p-2`}
                                >
                                  <span className="item_text">Portfolio</span>
                                  <i className="mkdf-menu-arrow fa fa-angle-down"></i>
                                </span>
                              </a>
                            ) : (
                              <Link
                                to={item?.linkTo}
                                className={`${
                                  pathname === item?.linkTo ? "current" : ""
                                }`}
                              >
                                <span
                                  className={`${
                                    pathname === item?.linkTo
                                      ? "activeClassBg"
                                      : ""
                                  } item_outer p-2`}
                                >
                                  <span className="item_text">
                                    {item?.name}
                                  </span>
                                  <i className="mkdf-menu-arrow fa fa-angle-down"></i>
                                </span>
                              </Link>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="mkdf-mobile-header">
        <div className="mkdf-mobile-header-inner">
          <div className="mkdf-mobile-header-holder">
            <div className="mkdf-grid">
              <div className="d-flex justify-content-between align-items-center">
                <div onClick={toggleMobileMenu}>
                  <div
                    className={`${
                      isMobileMenuOpen ? "mkdf-mobile-menu-opened" : ""
                    } `}
                  >
                    <span className="mkdf-mobile-menu-icon">
                      <i className="mkdf-icon-font-awesome fa fa-bars"></i>
                    </span>
                  </div>
                </div>
                <div className="mkdf-position-center">
                  <div className="mkdf-position-center-inner">
                    <div className="mkdf-mobile-logo-wrapper">
                      <Link itemProp="url" to="/" style={{ height: "70px" }}>
                        <img
                          itemProp="image"
                          src="/assets/images/logo.png"
                          width="auto"
                          height="auto"
                          alt="Mobile Logo"
                          loading="lazy"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav
            className="mkdf-mobile-nav"
            role="navigation"
            aria-label="Mobile Menu"
            style={
              isMobileMenuOpen
                ? {
                    display: "block",
                    height: "350px",
                  }
                : { display: "none" }
            }
          >
            <div className="mkdf-grid">
              <ul id="menu-standard-2" className="">
                {headerMenuData?.map((item, index) => {
                  return (
                    <li
                      className={`${
                        pathname === item?.linkTo ? "mkdf-active-item" : ""
                      } menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has_sub`}
                      key={index}
                    >
                      {item?.name === "Portfolio" &&
                      item?.linkTo === "/portfolio" ? (
                        <a
                          href={item?.linkTo}
                          className={`mkdf-mobile-no-link ${
                            pathname === item?.linkTo ? "current" : ""
                          }`}
                          onClick={() => {
                            toggleMobileMenu();
                            window.location.reload();
                          }}
                          style={{ color: "#ee8b17" }}
                        >
                          <span
                            className={`${
                              pathname === item?.linkTo ? "activeClassBg" : ""
                            } item_outer p-2`}
                          >
                            {item?.name}
                          </span>
                        </a>
                      ) : (
                        <Link
                          to={item?.linkTo}
                          className={`mkdf-mobile-no-link ${
                            pathname === item?.linkTo ? "current" : ""
                          }`}
                          onClick={toggleMobileMenu}
                          style={{ color: "#ee8b17" }}
                        >
                          <span
                            className={`${
                              pathname === item?.linkTo ? "activeClassBg" : ""
                            } item_outer p-2`}
                          >
                            {item?.name}
                          </span>
                        </Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
