import React from "react";
import { Details } from "../../../constant/Details";

export default function Maintenance() {
  const supportAndMaintenance = [
    {
      image: "/assets/images/technical-improvements.svg",
      title: "Modifications & Technical Improvements",
      alt: "modifications-technical-improvements-img",
      description:
        "We can modify if any feature is not working as expected or if a new feature is to be added to enhance UI/UX by doing a thorough code review, checking technical functionalities, understanding how modules are interacting, and so on. We additionally manage code readability for future needs and latest version control.",
    },
    {
      image: "/assets/images/bugglitch-removal.svg",
      title: "Bug & Glitch Removal",
      alt: "bug-glitch-removal-img",
      description:
        "We identify the bug and try to fix it from the backend instead of making changes in multiple platforms (React Native, React JS, Node JS) to save time and avoid version updates on the store for minor changes/bug fixes. We then find the route course of the bug and fix it permanently by applying the relevant solution.",
    },
    {
      image: "/assets/images/performance-improvements.svg",
      title: "Performance Improvements",
      alt: "performance-improvements-img",
      description:
        "We use GT Matrix & Google services to check the performance and responsiveness of the app on various devices across browsers. We then remove unnecessary JS and CSS images for query optimization, minimize long/unnecessary responses generated from API for quick turnaround of data display & reduce load time.",
    },
  ];

  return (
    <>
      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-center">
                    <div
                      className="mkdf-eh-item mkdf-horizontal-alignment-center"
                      data-item-class="mkdf-eh-custom-9465"
                      data-1367-1600="0px 10px"
                      data-1025-1366="0px 220px"
                      data-769-1024="0px 70px"
                      data-681-768="0px 70px"
                      data-680="0px 37px"
                    >
                      <div className="mkdf-eh-item-inner">
                        <div className="mkdf-eh-item-content">
                          <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space text-center">
                            <div className="mkdf-st-inner">
                              <h3
                                className="mkdf-st-title"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                Software Support and Maintenance
                              </h3>
                              <p
                                className="mkdf-st-text font-size-line-height"
                                data-aos="fade-left"
                                data-aos-duration="1000"
                              >
                                {Details?.COMPANY_NAME} can provide IT support
                                and maintenance services. Our Software
                                maintenance and support experts are always
                                available to respond to your needs, whether it's
                                ongoing administrative management, hosting and
                                server configuration, technical consulting,
                                third-party integration, or enhancement of your
                                website or web-based software.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid pt-5 pb-5">
            <div className="row">
              {supportAndMaintenance?.map((item, key) => {
                return (
                  <div
                    className="col-xl-4 col-md-6 mb-3 d-flex align-items-stretch"
                    key={key}
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <div className="card shadow border-0">
                      <div className="card-body d-flex flex-column align-items-center text-align-center mkdf-iwt-inner">
                        <img
                          src={item?.image}
                          className="attachment-full size-full"
                          height={45}
                          width={45}
                          alt={item?.alt}
                          decoding="async"
                          loading="lazy"
                        />
                        <h5 className="fw-bold mb-0">{item?.title}</h5>
                        <p className="mkdf-st-text font-size-line-height mb-4">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
