import React from "react";

export default function Iframe() {
  return (
    <div
      className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12"
      data-aos="fade-right"
      data-aos-duration="1000"
    >
      <div className="vc_column-inner">
        <div className="wpb_wrapper">
          <div className="mkdf-google-map-holder">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14880.350084863761!2d72.7710553!3d21.1886819!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2f1339efacfc58f2!2s3Elixir%20Solutions%20(Web%20Development)!5e0!3m2!1sen!2sin!4v1656312537054!5m2!1sen!2sin"
              aria-hidden="false"
              width="100%"
              height="550"
              className="border-0"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps Embed"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
