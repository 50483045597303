import React from "react";
import MainContent from "./MainContent";
import WorkList from "./WorkList";

export default function Portfolio() {
  return (
    <div className="mkdf-full-width">
      <div className="mkdf-full-width-inner">
        <div className="mkdf-grid-row">
          <div className="mkdf-page-content-holder mkdf-grid-col-12">
            <section className="wpb-content-wrapper">
              <MainContent />

              <WorkList />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
