import React from "react";
import MainContent from "./MainContent";
import WhoWeAre from "./WhoWeAre";
import VisionMission from "./VisionMission";
import OurCoreValues from "./OurCoreValues";
import Industries from "./Industries";
import Technologies from "./Technologies";
import WeOfferOurClients from "./WeOfferOurClients";
import HardWork from "./HardWork";

export default function AboutUs() {
  return (
    <div className="mkdf-full-width">
      <div className="mkdf-full-width-inner">
        <div className="mkdf-grid-row">
          <div className="mkdf-page-content-holder mkdf-grid-col-12">
            <section className="wpb-content-wrapper">
              <MainContent />

              <WhoWeAre />

              <VisionMission />

              <OurCoreValues />

              <Industries />

              <Technologies />

              <WeOfferOurClients />

              <HardWork />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
