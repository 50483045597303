import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getTeams } from "../../../utils/baseAPI";

export default function Teams() {
  const [usersData, setUsersData] = useState([]);

  const fetchData = async () => {
    let teams = await getTeams(`teams/all`);
    setUsersData(teams);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div
            className="vc_row wpb_row vc_row-fluid"
            style={{ paddingBottom: "60px" }}
          >
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-center">
                    <div
                      className="mkdf-eh-item mkdf-horizontal-alignment-center"
                      data-item-class="mkdf-eh-custom-9747"
                      data-1367-1600="0px 144px 0px 176px"
                      data-1025-1366="0px 144px 0px 176px"
                      data-769-1024="0px 0px 0px 0px"
                      data-681-768="0px 0px 0px 0px"
                      data-680="0px 58px 0px 46px"
                    >
                      <div className="mkdf-eh-item-inner">
                        <div
                          className="mkdf-eh-item-content mkdf-eh-custom-9747"
                          style={{ padding: "0px 245px 0px 218px" }}
                        >
                          <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space text-center">
                            <div className="mkdf-st-inner mt-3">
                              <h3
                                className="mkdf-st-title"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                Team Behind Our Success
                              </h3>
                              <p
                                className="mkdf-st-text font-size-line-height"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                Meet the driving force behind our success.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid pb-5">
            <div className="row justify-content-center">
              {usersData?.map((item, key) => {
                return (
                  <div
                    className="col-xl-4 col-md-6 mb-3 d-flex align-items-stretch"
                    key={key}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <div className="card mkdf-iwt border-0">
                      <div className="card-body d-flex h-100 flex-column align-items-center text-align-center mkdf-iwt-inner">
                        <Link
                          to={`/profile/${item?.name
                            .replace(/\s/g, "-")
                            .toLowerCase()}`}
                        >
                          <img
                            height="150px"
                            width="150px"
                            src={item?.image}
                            className="attachment-full size-full border-radius-100 mark"
                            alt="team-person-img"
                            decoding="async"
                            loading="lazy"
                            style={{ maxHeight: "150px" }}
                          />
                          <h4 className="fw-bold mb-2">{item?.name}</h4>
                        </Link>
                        <h6 className="mt-0">{item?.position}</h6>
                        <p className="mkdf-st-text font-size-line-height mb-4">
                          {item?.description}
                        </p>
                        <div className="mt-auto align-self-start w-100 d-flex justify-content-evenly ">
                          <span
                            className="mkdf-icon-shortcode mkdf-normal"
                            data-color="#ff8a73"
                          >
                            <a
                              href={item?.linkedin}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                width="26"
                                height="26"
                                src="/assets/images/team/linkedin.svg"
                                className="attachment-full size-full"
                                alt="linkedin-img"
                                decoding="async"
                                loading="lazy"
                              />
                            </a>
                          </span>
                          <span
                            className="mkdf-icon-shortcode mkdf-normal"
                            data-color="#7666a5"
                          >
                            <a
                              href={`mailto:${item?.email}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                width="25"
                                height="25"
                                src="/assets/images/team/mail.svg"
                                className="attachment-full size-full"
                                alt="mail-img"
                                decoding="async"
                                loading="lazy"
                              />
                            </a>
                          </span>
                          <a
                            href={item?.calendly}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              width="26"
                              height="26"
                              src="/assets/images/team/calendly1.png"
                              className="attachment-full size-full"
                              alt="calendly-img"
                              decoding="async"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
