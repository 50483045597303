import React from "react";

export default function Technologies() {
  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="mkdf-eh-item-inner">
            <div className="mkdf-eh-item-content mkdf-eh-custom-2383 pb-5">
              <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space text-center">
                <div className="mkdf-st-inner">
                  <h3
                    className="mkdf-st-title"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Technologies
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="mkdf-row-grid-section-wrapper">
            <div className="mkdf-row-grid-section">
              <div className="vc_row wpb_row vc_row-fluid pt-5">
                <div className="mkdf-row-grid-section-wrapper">
                  <div className="mkdf-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid">
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12">
                        <div className="vc_column-inner vc_custom_1528200436424">
                          <div className="wpb_wrapper">
                            <div className="mkdf-iwt">
                              <div className="mkdf-iwt-inner clearfix mkdf-iwt-icon-left mkdf-iwt-icon-medium">
                                <div className="mkdf-iwt-icon">
                                  <img
                                    width="44"
                                    height="44"
                                    src="/assets/wp-content/uploads/2018/06/p5-check-img3.png"
                                    className="attachment-full size-full"
                                    alt="aboutme-img"
                                    decoding="async"
                                    loading="lazy"
                                  />
                                </div>
                                <div className="mkdf-iwt-content">
                                  <h4 className="mkdf-iwt-title mt-2">
                                    <span className="mkdf-iwt-title-text">
                                      Programming Language
                                    </span>
                                  </h4>
                                  <ul>
                                    <p className="mkdf-iwt-text">
                                      <li data-aos="zoom-out-right" data-aos-duration="500">PHP</li>
                                      <li data-aos="zoom-out-right" data-aos-duration="600">Python</li>
                                    </p>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12">
                        <div className="vc_column-inner vc_custom_1528200436424">
                          <div className="wpb_wrapper">
                            <div className="mkdf-iwt">
                              <div className="mkdf-iwt-inner clearfix mkdf-iwt-icon-left mkdf-iwt-icon-medium">
                                <div className="mkdf-iwt-icon">
                                  <img
                                    width="44"
                                    height="44"
                                    src="/assets/wp-content/uploads/2018/06/p5-check-img3.png"
                                    className="attachment-full size-full"
                                    alt="aboutme-img"
                                    decoding="async"
                                    loading="lazy"
                                  />
                                </div>
                                <div className="mkdf-iwt-content">
                                  <h4 className="mkdf-iwt-title mt-2">
                                    <span className="mkdf-iwt-title-text">
                                      Frameworks
                                    </span>
                                  </h4>
                                  <ul>
                                    <p className="d-flex mkdf-iwt-text">
                                      <span className="me-4">
                                        <li data-aos="zoom-out-right" data-aos-duration="500">Laravel</li>
                                        <li data-aos="zoom-out-right" data-aos-duration="600">Django</li>
                                        <li data-aos="zoom-out-right" data-aos-duration="700">AngularJS</li>
                                      </span>
                                      <span>
                                        <li data-aos="zoom-out-right" data-aos-duration="800">NodeJS</li>
                                        <li data-aos="zoom-out-right" data-aos-duration="900">VueJS</li>
                                        <li data-aos="zoom-out-right" data-aos-duration="1000">ReactJS</li>
                                      </span>
                                    </p>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-12">
                        <div className="vc_column-inner vc_custom_1528200447062">
                          <div className="wpb_wrapper">
                            <div className="mkdf-iwt">
                              <div className="mkdf-iwt-inner clearfix mkdf-iwt-icon-left mkdf-iwt-icon-medium">
                                <div className="mkdf-iwt-icon">
                                  <img
                                    width="44"
                                    height="44"
                                    src="/assets/wp-content/uploads/2018/06/p5-check-img3.png"
                                    className="attachment-full size-full"
                                    alt="check-img"
                                    decoding="async"
                                    loading="lazy"
                                  />
                                </div>
                                <div className="mkdf-iwt-content">
                                  <h4 className="mkdf-iwt-title mt-2">
                                    <span className="mkdf-iwt-title-text">
                                      Platforms
                                    </span>
                                  </h4>
                                  <ul>
                                    <p className="mkdf-iwt-text">
                                      <li data-aos="zoom-out-right" data-aos-duration="500">Google Cloud Platform</li>
                                      <li data-aos="zoom-out-right" data-aos-duration="600">AWS</li>
                                      <li data-aos="zoom-out-right" data-aos-duration="700">Heroku</li>
                                    </p>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
