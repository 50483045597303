import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./utils/PageNotFound";
import Layout from "./components/Layout/Layout";
import Home from "./components/Pages/Home";
import AboutUs from "./components/Pages/AboutUs";
import ContactUs from "./components/Pages/ContactUs";
import Services from "./components/Pages/Services";
import Clients from "./components/Pages/Clients";
import OurTeam from "./components/Pages/OurTeam";
import Profile from "./components/Pages/OurTeam/Profile";
import Career from "./components/Pages/Career";
import Portfolio from "./components/Pages/Portfolio";
import JobDetails from "./components/Pages/Career/JobDetails";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    const loadScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "/assets/wp-content/themes/foton/assets/js/modules.min6fb3.js?ver=6.1.3";
      script.id = "foton-mikado-modules-js";

      document.head.appendChild(script);
    };
    loadScript();

    return () => {
      const existingScript = document.getElementById("foton-mikado-modules-js");
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [location.pathname]);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route exact path="/*" element={<PageNotFound />} />
        <Route element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/our-team" element={<OurTeam />} />
          <Route exact path="/profile/:name" element={<Profile />} />
          <Route exact path="/our-clients" element={<Clients />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/our-services" element={<Services />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/job/:name" element={<JobDetails />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
