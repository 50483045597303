import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { Details } from "../../../constant/Details";

export default function HardWork() {
  const [elementInViewport, setElementInViewport] = useState(false);

  const hardWorkDeliver = [
    { name: "Good Deliver", number: "100", title: "Projects" },
    { name: "Globally Trusted", number: "30", title: "Countries" },
    { name: "Recognized by", number: "4", title: "Award" },
    { name: "Blessed with", number: "55", title: "Customer Retention" },
  ];

  return (
    <>
      <div className="mkdf-row-grid-section-wrapper pb-5">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid">
            <div className="mkdf-eh-item-inner">
              <div className="mkdf-eh-item-content mkdf-eh-custom-2383">
                <div className="d-flex">
                  <div className="col-md-12">
                    <div
                      className="d-lg-flex justify-content-between align-items-center"
                      style={{
                        padding: "70px 16px",
                      }}
                    >
                      <div
                        className="col-lg-5 col-md-12"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                      >
                        <img
                          width="511"
                          height="345"
                          src="/assets/images/about_banner.png"
                          className="attachment-full size-full"
                          alt="about-banner-img"
                          decoding="async"
                          loading="lazy"
                          style={{ borderRadius: "30px" }}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div>
                          <h4
                            className="fs-2"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            6+ Years of Trust & Hard Work
                          </h4>
                          <p
                            className="mkdf-st-text font-size-line-height"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                          >
                            At {Details?.COMPANY_NAME}, we've spent 6+ years
                            perfecting our craft, combining cutting-edge
                            technology and affordable pricing to deliver
                            customized solutions tailored to our client's needs.
                            We focus on creating reliable, appealing,
                            easy-to-use, and scalable deliverables. So join us
                            to bring your vision to life with our exceptional
                            technological expertise!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <ScrollTrigger
                  onEnter={() => setElementInViewport(true)}
                  onExit={() => setElementInViewport(false)}
                  className="d-lg-flex justify-content-center"
                >
                  {hardWorkDeliver?.map((item, key) => {
                    return (
                      <div
                        className="col-xl-3 col-lg-3 col-md-6 text-center"
                        key={key}
                      >
                        <h5 className="fw-bold m-0">{item?.name}</h5>
                        {elementInViewport ? (
                          <h4 className="fw-bold text-black fs-2">
                            <CountUp start={0} end={item?.number} delay={1} /> +
                          </h4>
                        ) : (
                          <h4 className="fw-bold text-black fs-2">0 +</h4>
                        )}
                        <h5 className="fw-bold text-body-secondary">
                          {item?.title}
                        </h5>
                      </div>
                    );
                  })}
                </ScrollTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
