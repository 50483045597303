const btnBackground = "linear-gradient(135deg, #eacb19 5%, #f9417a 90%)";
const footerBackground = "linear-gradient(135deg,#eacb19 5%,#f9417a 95%)";
const footerBottomBackground = "#fff";

export const Style = {
  topBar: {
    background: btnBackground,
  },
  footerSection: {
    background: footerBackground,
  },
  footerBottomSection: {
    background: footerBottomBackground,
    color: "#000",
  },
  buttonBackground: {
    padding: "12px 40px",
    background: btnBackground,
  },
  moreButton: {
    borderRadius: "10px",
    padding: "10px 22px",
    background: "#ee8b17",
    color: "#fff",
  },
  portfolioButton: {
    borderRadius: "10px",
    background: "#ee8b17",
    color: "#fff",
  },
  submitButton: {
    background: "#ee8b17",
    color: "#fff",
  },
  portfolioCardStyle: {
    boxShadow: "0 0 10px #7e7d7d",
    marginTop: "25px",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    width: "fit-content",
  },
  portfolioImageStyle: {
    padding: "7px",
    borderRadius: "0",
    background: btnBackground,
  },
  jobCardBackground: {
    background: "antiquewhite",
    borderRadius: "4px",
    padding: "1rem 1.5rem",
  },
  jobCardResponseTime: {
    alignItems: "center",
    background: "rgb(51 51 153 / 2%)",
    border: "1px solid rgb(234 98 72)",
    borderRadius: "6px",
    display: "flex",
    margin: "1.3rem 0 1rem",
    padding: "1rem",
  },
  jobCardBoltStyle: {
    color: "rgb(234 98 72)",
    fontSize: "20px",
    paddingRight: "10px",
  },
  buttonStyle: {
    background: "#ee8b17",
    color: "#fff",
  },
};
