import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { Label } from "../constant/Label";

export default function CarouselCard({ data }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      autoPlay
      showDots
      infinite
      arrows={false}
      autoPlaySpeed={5000}
      dotListClass="custom-dot-list-style"
    >
      {data?.map((item, key) => {
        return (
          <div
            className="shadow m-2"
            key={key}
            style={{
              height: "100%",
            }}
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <div className="m-auto text-align-center p-3">
              <div className="mkdf-team-image p-1 border-radius-100">
                <img
                  height="100"
                  width="100"
                  src={
                    item?.job_title === Label?.JSD
                      ? "/assets/images/career/Junior-software-developer.png"
                      : item?.job_title === Label?.BDE
                      ? "/assets/images/career/bde.webp"
                      : item?.job_title === Label?.HR
                      ? "/assets/images/career/human-resource-management.png"
                      : item?.job_title === Label?.PA
                      ? "/assets/images/career/personal-assistant-services.jpg"
                      : item?.job_title === Label?.MSD
                      ? "/assets/images/career/mern-stack-developer.png"
                      : item?.image
                  }
                  alt={`${key + 1}-img`}
                  className="attachment-full size-full"
                  decoding="async"
                  loading="lazy"
                />
              </div>
            </div>
            {item?.id || item.experience ? (
              <div className="card-body text-center p-3">
                <h4 className="m-0">{item?.job_title}</h4>
                <p>Experience : {item?.experience}</p>
                <p className="card-text mb-4">
                  <Link
                    to={`/job/${item?.job_title
                      .replace(/\s/g, "-")
                      .toLowerCase()}`}
                    className="text-warning fw-bold"
                  >
                    View More & Apply
                  </Link>
                </p>
              </div>
            ) : (
              <div className="card-body text-center p-3">
                <h4 className="m-0">{item?.name}</h4>
                <p className="mkdf-st-text font-size-line-height">
                  {item?.description}
                </p>
              </div>
            )}
          </div>
        );
      })}
    </Carousel>
  );
}
