import React, { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { toast } from "react-toastify";
import CONFIG from "../../../utils/config";
import { Style } from "../../../common/Style";
import Loader from "../../../utils/Loader";
import { Details } from "../../../constant/Details";

export default function GetInTouch() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState([
    {
      fname: "",
      email: "",
      phone: "",
      feild: "",
      message: "",
    },
  ]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      !/[0-9]/.test(String.fromCharCode(charCode)) ||
      inputValue?.phone?.length >= 10
    ) {
      e.preventDefault();
    }
  };

  const contactdeatil = () => {
    var epattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var eresult = epattern.test(inputValue?.email);

    if (!inputValue?.fname) {
      toast.dismiss();
      toast.error("Enter Full Name");
      return false;
    }
    if (!inputValue?.email) {
      toast.dismiss();
      toast.error("Enter Email");
      return false;
    }
    if (!eresult) {
      toast.dismiss();
      toast.error("Enter Valid Email");
      return false;
    }
    if (!inputValue?.phone) {
      toast.dismiss();
      toast.error("Enter Phone Number");
      return false;
    }
    if (inputValue?.phone?.length !== 10) {
      toast.dismiss();
      toast.error("Phone Number must be 10 digit");
      return false;
    }
    if (!inputValue?.feild) {
      toast.dismiss();
      toast.error("Select Interest Job");
      return false;
    }
    if (!inputValue?.message) {
      toast.dismiss();
      toast.error("Enter Message");
      return false;
    }

    let data = {
      name: inputValue?.fname,
      email: inputValue?.email,
      phone: inputValue?.phone,
      position: inputValue?.feild,
      message: inputValue?.message,
    };
    contactDataAPI(data);
  };

  const contactDataAPI = (data) => {
    if (data) {
      setIsLoading(true);
      axios
        .post(CONFIG.APIURL + CONFIG.CONTACT_US_URL, data)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            swal(Details?.CONTACT_RESPONSE);
            setInputValue("");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          swal(Details?.CONTACT_ERROR);
        });
    }
  };

  return (
    <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12">
      <div className="vc_column-inner">
        <div className="wpb_wrapper">
          <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768">
            <div
              className="mkdf-eh-item"
              data-item-class="mkdf-eh-custom-7110"
              data-769-1024="50px 0 0 0"
              data-681-768="50px 0 0 0"
              data-680="50px 0 0 0"
            >
              <div className="mkdf-eh-item-inner">
                <div
                  className="mkdf-eh-item-content mkdf-eh-custom-7110"
                  style={{ padding: "0 0 31px 31px" }}
                >
                  <div className="wpb_text_column wpb_content_element pb-3">
                    <div className="wpb_wrapper">
                      <h3>Get In Touch With Us</h3>
                    </div>
                  </div>
                  <div
                    className="wpcf7 no-js"
                    id="wpcf7-f1216-p1183-o1"
                    lang="en-US"
                    dir="ltr"
                  >
                    <div className="screen-reader-response">
                      <p
                        role="status"
                        aria-live="polite"
                        aria-atomic="true"
                      ></p>
                      <ul></ul>
                    </div>
                    <form className="row">
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="fname"
                          className="form-label fw-bold text-black"
                        >
                          Full Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control border"
                          id="fname"
                          name="fname"
                          value={inputValue?.fname || ""}
                          onChange={handleInputChange}
                          placeholder="Full Name&nbsp;*"
                          style={{
                            textTransform: "none",
                          }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="email"
                          className="form-label fw-bold text-black"
                        >
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control border"
                          id="email"
                          name="email"
                          value={inputValue?.email || ""}
                          onChange={handleInputChange}
                          placeholder="Email&nbsp;*"
                          style={{
                            textTransform: "none",
                          }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="phone"
                          className="form-label fw-bold text-black"
                        >
                          Phone <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control border"
                          id="phone"
                          name="phone"
                          value={inputValue?.phone || ""}
                          onChange={handleInputChange}
                          placeholder="Phone&nbsp;*"
                          maxLength="10"
                          onKeyPress={handleKeyPress}
                          style={{
                            textTransform: "none",
                          }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="interest"
                          className="form-label fw-bold text-black"
                        >
                          Interest ? <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select"
                          id="interest"
                          name="feild"
                          value={inputValue?.feild || ""}
                          onChange={handleInputChange}
                          style={{ padding: "12px" }}
                        >
                          <option value="">Select</option>
                          <option>Website Maintenance (After Care)</option>
                          <option>Web Design</option>
                          <option>Web Development</option>
                          <option>E-commerce Development</option>
                          <option>Business Consultation</option>
                          <option>Job</option>
                          <option>Other</option>
                        </select>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label
                          htmlFor="comment"
                          className="form-label fw-bold text-black"
                        >
                          Type your message here{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control border"
                          id="comment"
                          name="message"
                          value={inputValue?.message || ""}
                          onChange={handleInputChange}
                          placeholder="Type your message here&nbsp;*"
                          style={{
                            textTransform: "none",
                          }}
                        />
                      </div>
                    </form>
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn col-5 bg-elist d-flex justify-content-center align-items-center"
                        onClick={contactdeatil}
                        style={Style.submitButton}
                        disabled={isLoading}
                      >
                        {isLoading && <Loader />}
                        <i className="bi bi-send m-2"></i>
                        Submit
                      </button>
                    </div>
                    <p className="text-danger text-center">
                      We'll get back to you in 24 hours.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
