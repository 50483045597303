import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getTeams } from "../../../utils/baseAPI";
import { Label } from "../../../constant/Label";

export default function JoinOurTeam() {
  const [careerData, setCareerData] = useState([]);

  const fetchData = async () => {
    let career = await getTeams(`job/`);
    if (career) {
      setCareerData(career);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const imageStyle = {
    height: "70px",
  };

  return (
    <>
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  <h3
                    className="text-center"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Join Our Team
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid pt-4 vc_custom_1529567857034">
            <div className="row">
              {careerData?.map((item, key) => {
                const splitarr = item?.job_title?.split(" ");
                for (var i = 0; i < splitarr.length; i++) {
                  splitarr[i] =
                    splitarr[i].charAt(0).toLowerCase() + splitarr[i].slice(1);
                }
                const jobname = splitarr.join("-");

                return (
                  <div
                    className="col-xl-4 col-md-6 mb-3 d-flex align-items-stretch"
                    key={key}
                    data-aos="zoom-in-right"
                    data-aos-duration="1000"
                  >
                    <div className="shadow p-3 w-100">
                      <div className="card-body d-flex flex-column align-items-center text-align-center mkdf-iwt-inner">
                        <div>
                          <img
                            width="70px"
                            height="70px"
                            src={
                              item?.job_title === Label?.JSD
                                ? "/assets/images/career/Junior-software-developer.png"
                                : item?.job_title === Label?.BDE
                                ? "/assets/images/career/bde.webp"
                                : item?.job_title === Label?.HR
                                ? "/assets/images/career/human-resource-management.png"
                                : item?.job_title === Label?.PA
                                ? "/assets/images/career/personal-assistant-services.jpg"
                                : item?.job_title === Label?.MSD
                                ? "/assets/images/career/mern-stack-developer.png"
                                : item?.image
                            }
                            className="attachment-full size-full"
                            alt={`${jobname}-img`}
                            decoding="async"
                            loading="lazy"
                            style={imageStyle}
                          />
                        </div>

                        <h5 className="fw-bold">{item?.job_title}</h5>
                        <p>Experience : {item?.experience}</p>
                        <p className="mb-4">
                          <Link
                            to={`/job/${item?.job_title
                              .replace(/\s/g, "-")
                              .toLowerCase()}`}
                            className="text-warning fw-bold"
                          >
                            View More & Apply
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
