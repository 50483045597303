import React from "react";
import { Style } from "../../../common/Style";

export default function WorkList() {
  const portfolioTitle = [
    {
      title: "Show all",
      filter: "",
    },
    {
      title: "Tourism",
      filter: ".portfolio-category-tourism",
    },
    {
      title: "Finance",
      filter: ".portfolio-category-finance",
    },
    {
      title: "Health Care",
      filter: ".portfolio-category-health-care",
    },
    {
      title: "Social Media",
      filter: ".portfolio-category-social-media",
    },
    {
      title: "Hospitality",
      filter: ".portfolio-category-hospitality",
    },
  ];

  const portfolio = [
    {
      title: "Air Market",
      link: " https://airmarket.io/t",
      image: "/assets/images/portfolio/AirMarket1.png",
      filter: "portfolio-category-tourism",
    },
    {
      title: "Steev",
      link: "https://steevlive.com/",
      image: "/assets/images/portfolio/Steev1.png",
      filter: "portfolio-category-social-media",
    },
    {
      title: "Drives and Detours",
      link: "https://www.drivesanddetours.com/",
      image: "/assets/images/portfolio/Drives1.png",
      filter: "portfolio-category-tourism",
    },
    {
      title: "Access My App",
      link: "https://play.google.com/store/apps/details?id=com.accesseap",
      image: "/assets/images/portfolio/accessMyEAP2.png",
      filter: "portfolio-category-hospitality",
    },
    {
      title: "Sprive",
      link: "https://sprive.com/",
      image: "/assets/images/portfolio/Sprive1.png",
      filter: "portfolio-category-health-care",
    },
    {
      title: "Mcgl",
      link: " https://mcglonline.com/auth",
      image: "/assets/images/portfolio/MCGL1.png",
      filter: "portfolio-category-finance",
    },
  ];

  return (
    <>
      <style jsx="true">
        {`
          article:hover .title-text-visit {
            opacity: 1;
            z-index: 2;
          }
          .mkdf-portfolio-list-holder.mkdf-pl-standard-shader
            article:hover
            .mkdf-pli-image:after {
            border-radius: 0;
          }
          .title-text-visit {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            padding: 8px 16px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.6);
            font-size: 2.5em;
            text-transform: uppercase;
            opacity: 0;
            transition-delay: 0.2s;
            transition-duration: 0.3s;
          }
        `}
      </style>

      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_1528282823964">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div
                    className="mkdf-portfolio-list-holder mkdf-grid-list mkdf-disable-bottom-space mkdf-pl-gallery mkdf-three-columns mkdf-normal-space mkdf-pl-standard-shader mkdf-pl-rounded-image mkdf-pl-pag-load-more mkdf-pl-has-filter"
                    data-type="gallery"
                    data-number-of-columns="three"
                    data-space-between-items="normal"
                    data-number-of-items="9"
                    data-image-proportions="square"
                    data-enable-fixed-proportions="no"
                    data-enable-image-shadow="no"
                    data-rounded-image="yes"
                    data-orderby="date"
                    data-order="ASC"
                    data-item-style="standard-shader"
                    data-content-bottom-margin="22px"
                    data-enable-title="yes"
                    data-title-tag="h4"
                    data-enable-category="yes"
                    data-enable-count-images="yes"
                    data-enable-excerpt="no"
                    data-excerpt-length="20"
                    data-pagination-type="load-more"
                    data-load-more-top-margin="0px"
                    data-filter="yes"
                    data-filter-order-by="name"
                    data-enable-article-animation="no"
                    data-portfolio-slider-on="no"
                    data-enable-loop="yes"
                    data-enable-autoplay="yes"
                    data-slider-speed="5000"
                    data-slider-speed-animation="600"
                    data-enable-navigation="yes"
                    data-enable-pagination="yes"
                    data-max-num-pages="4"
                    data-next-page="2"
                  >
                    <div className="mkdf-pl-filter-holder">
                      <div className="mkdf-plf-inner">
                        <ul>
                          {portfolioTitle?.map((item, key) => {
                            return (
                              <li
                                className="mkdf-pl-filter"
                                data-filter={item?.filter}
                                key={key}
                              >
                                <span>{item?.title}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="mkdf-pl-inner mkdf-outer-space clearfix">
                      {portfolio?.map((item, key) => {
                        return (
                          <article
                            className={`mkd-pl-item ${item?.filter} mkdf-item-space post-2062 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-tag-art portfolio-tag-branding`}
                            key={key}
                          >
                            <div className="mkdf-pl-item-inner">
                              <div
                                className="mkdf-pli-image"
                                style={Style.portfolioCardStyle}
                              >
                                <img
                                  width="auto"
                                  height="auto"
                                  src={item?.image}
                                  alt={`project-${key + 1}`}
                                  decoding="async"
                                  style={Style.portfolioImageStyle}
                                />
                                <a
                                  className="title-text-visit"
                                  href={item?.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Visit
                                </a>
                              </div>
                              <div className="mkdf-pli-text-holder box-content">
                                <div className="mkdf-pli-text-wrapper">
                                  <div className="mkdf-pli-text">
                                    <h4
                                      itemProp="name"
                                      className="mkdf-pli-title entry-title"
                                    >
                                      {item?.title}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </article>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
