import React, { useState, useEffect } from "react";
import moment from "moment";
import { getTeams } from "../../../../utils/baseAPI";

export default function TeamInformation() {
  const [usersData, setUsersData] = useState([]);

  const fetchData = async () => {
    let params = window.location.pathname.split("/");
    let replacestring = params[2].replaceAll("-", " ");
    const splitarr = replacestring.split(" ");

    for (var i = 0; i < splitarr.length; i++) {
      splitarr[i] = splitarr[i].charAt(0).toUpperCase() + splitarr[i].slice(1);
    }

    const name = splitarr.join(" ");

    let teams = await getTeams(`team/${name}`);
    setUsersData(teams);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid pt-5 pb-5">
          <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-12 vc_col-md-12">
            <div className="wpb_wrapper">
              <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768">
                <div
                  className="mkdf-eh-item"
                  data-item-class="mkdf-eh-custom-9751"
                  data-681-768="50px 0 0 0"
                  data-680="50px 0 0 0"
                >
                  <div className="mkdf-eh-item-inner">
                    <div className="mkdf-eh-item-content mkdf-eh-custom-9751">
                      <div className="mkdf-iwt">
                        <div className="mkdf-iwt-inner clearfix mkdf-iwt-icon-left-from-title-and-text mkdf-iwt-icon-medium mkdf-iwt-show-regular-shadow mkdf-iwt-align-left p-3">
                          <div className="mkdf-iwt-content w-100">
                            <div className="d-lg-flex">
                              <div className="col-lg-1">
                                <img
                                  width="100"
                                  height="100"
                                  src={usersData?.image}
                                  className="attachment-full size-full border-radius-100 mark"
                                  alt="profile-img"
                                  decoding="async"
                                  loading="lazy"
                                />
                              </div>
                              <div className="col-lg-11 mkdf-iwt-title ms-lg-3">
                                <div className="mkdf-iwt-title-text d-lg-flex justify-content-between">
                                  <div className="d-flex m-0">
                                    <h4 className="m-0">{usersData?.name}</h4>
                                    <span className="ms-2 verified-img">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-patch-check-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <span className="font-size-16 fw-normal">
                                    {usersData?.position}
                                  </span>
                                </div>
                                <div className="row gy-2 text-secondary pt-3 pb-3">
                                  <div className="col-auto">
                                    <span className="font-size-16 fw-normal">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        fill="currentColor"
                                        className="bi bi-briefcase"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"></path>
                                      </svg>
                                      <span className="ms-1">
                                        {usersData?.experience}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <span className="font-size-16 fw-normal">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        fill="currentColor"
                                        className="bi bi-geo-alt"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"></path>
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                      </svg>
                                      <span className="ms-1">
                                        {usersData?.location}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="col-auto">
                                    <span className="font-size-16 fw-normal">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="17"
                                        fill="currentColor"
                                        className="bi bi-clock"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path>
                                      </svg>
                                      <span className="ms-1">
                                        {moment(usersData?.time).fromNow()}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div className="row gy-2">
                                  {usersData?.language?.map((item, key) => {
                                    let colors = [
                                      "#28a745",
                                      "orange",
                                      "#0f92fb",
                                      "red",
                                      "#45457a",
                                    ];
                                    const backgroundColor =
                                      colors[key % colors?.length];
                                    const itemStyle = {
                                      borderRadius: "50px",
                                      background: backgroundColor,
                                      padding: "4px 12px",
                                    };

                                    return (
                                      <div className="col-auto" key={key}>
                                        <div
                                          className="text-capitalize text-white text-center"
                                          style={itemStyle}
                                        >
                                          {item?.technologies}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mkdf-iwt-content w-100">
                            <h4 className="mb-0">Profile</h4>
                            {usersData?.profile?.map((data, key) => {
                              return (
                                <p className="mt-0" key={key}>
                                  {data?.description}
                                </p>
                              );
                            })}
                          </div>

                          <div className="mkdf-iwt-content w-100">
                            <h4 className="mb-0">Responsibility :</h4>
                            <ul>
                              {usersData?.roles?.map((data, key) => {
                                return (
                                  <li className="mb-1" key={key}>
                                    {data?.description}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>

                          <div className="mkdf-iwt-content">
                            <ul className="d-lg-flex d-md-flex align-items-center list-unstyled">
                              <div>
                                <h4 className="me-4">Connect :</h4>
                              </div>
                              <div className="d-flex">
                                {usersData?.linkedin && (
                                  <li className="me-4">
                                    <a
                                      href={usersData?.linkedin}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        width="28"
                                        height="28"
                                        src="/assets/images/team/linkedin.svg"
                                        className="attachment-full size-full"
                                        alt="linkedin-img"
                                        decoding="async"
                                        loading="lazy"
                                      />
                                    </a>
                                  </li>
                                )}
                                {usersData?.calendly && (
                                  <li className="me-4">
                                    <a
                                      href={usersData?.calendly}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        width="20px"
                                        height="auto"
                                        src="https://3ecenter.s3.ap-south-1.amazonaws.com/c.svg"
                                        className="attachment-full size-full"
                                        alt="calendly-img"
                                        decoding="async"
                                        loading="lazy"
                                      />
                                    </a>
                                  </li>
                                )}
                                {usersData?.email && (
                                  <li className="me-4">
                                    <a
                                      href={`mailto:${usersData?.email}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        width="26"
                                        height="26"
                                        src="/assets/images/team/mail.svg"
                                        className="attachment-full size-full"
                                        alt="mail-img"
                                        decoding="async"
                                        loading="lazy"
                                      />
                                    </a>
                                  </li>
                                )}
                                {usersData?.upwork && (
                                  <li className="me-4">
                                    <a
                                      href={usersData?.upwork}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        width="20"
                                        height="20"
                                        src="/assets/images/team/upwork.svg"
                                        className="attachment-full size-full"
                                        alt="upwork-img"
                                        decoding="async"
                                        loading="lazy"
                                      />
                                    </a>
                                  </li>
                                )}
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
