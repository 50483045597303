import React from "react";
import MainContent from "./MainContent";
import OurServices from "./OurServices";
import Maintenance from "./Maintenance";
import BenifitPriceModel from "./BenifitPriceModel";
import TimeZone from "./TimeZone";
import OurKeyFeatures from "./OurKeyFeatures";

export default function Services() {
  return (
    <>
      <style jsx="true">
        {`
          .react-clock__face {
            border: 1px solid #333399;
          }
          .react-clock__hand__body {
            background: #253858;
          }
          .time-zone-image {
            position: relative;
            left: 25%;
          }
          @media (max-width: 768px) {
            .time-zone-desc {
              position: relative;
              top: 50px;
            }
          }
          @media (max-width: 480px) {
            .time-zone-image {
              left: 10%;
            }
          }
        `}
      </style>

      <div className="mkdf-full-width">
        <div className="mkdf-full-width-inner">
          <div className="mkdf-grid-row">
            <div className="mkdf-page-content-holder mkdf-grid-col-12">
              <section className="wpb-content-wrapper">
                <MainContent />

                <OurServices />

                <Maintenance />

                <BenifitPriceModel />

                <TimeZone />

                <OurKeyFeatures />
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
